/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Router from 'next/router';

import Notification from '../components/Notification';
import { Meta } from '../components';

const Error = () => {
  const handleSubmit = () => {
    Router.back();
  }

  return (
    <>
      <Meta title="Halaman Tidak Ditemukan - Lifepal" />
      <Notification title="not-found" action={handleSubmit} />
    </>
  );
}

export default Error;
