import React from 'react';

import { useRouter } from 'next/router';

import { Avatar } from '../Avatar/Avatar';
import './Notification.module.scss';

const Notification = ({ title, action, isFinish }) => {

  const router = useRouter();

  const getPageContentByNotification = (notificationTitle) => {
    const pageContent = {
      'success': () => ({
        image: '/static/images/notification/success.png',
        title: 'Terima Kasih Telah mempercayai Lifepal.',
        body: 'Pembayaran Anda telah berhasil dan akan kami proses. Detail pembayaran akan kami kirim ke email Anda.',
        button: 'Kembali Ke Home',
      }),
      'failure': () => ({
        image: '/static/images/notification/failure.png',
        title: 'Pembayaran Anda Gagal, Silahkan Coba Lagi.',
        body: 'Mungkin Anda salah memasukan Alamat, atau halaman sudah tidak ada.',
        button: 'Kembali Ke Polis Saya',
      }),
      'not-found': () => ({
        image: '/static/images/notification/not-found.png',
        title: 'Yah, Halaman Tidak Ditemukan.',
        body: 'Mungkin Anda salah memasukan Alamat, atau halaman sudah tidak ada.',
        button: 'Kembali Ke Halaman Sebelumnya',
      }),
      'no-policy': () => ({
        image: '/static/images/notification/no-policy.png',
        title: 'Anda Belum Terlindungi, nih',
        body: 'Yuk, cari asuransi yang sesuai kebutuhan dan anggaranmu sekarang.',
        button: 'Cari Asuransi Untuk Saya',
      }),
      'no-leads': () => ({
        image: '/static/images/notification/no-policy.png',
        title: 'Anda Belum Memiliki Penjualan.',
        body: 'Yuk mulai lakukan penjualan sekarang!',
        button: 'Lakukan Penjualan',
      }),
    }

    return pageContent[notificationTitle]();
  }

  const data = getPageContentByNotification(title);

  return (
    <div className="notification__wrapper">
      <div className="notification__wrapper--row">
        <div className="notification__wrapper--col">
          <div className="notification__wrapper--card">
            <div className="card__left">
              <Avatar src={data.image} className="card__left--image" alt="image-notification" />
            </div>
            <div className="card__right">
              <div>
                <p className="heading-xl-b title">{data.title}</p>
                <p className="body">
                  {data.body}
                </p>
                {
                  (!router?.query.is_mobile) && !isFinish && (
                    <button
                      onClick={action}
                      className="mt-3 btn btn-block btn-primary"
                    >{data.button}</button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;